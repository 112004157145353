@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');

/* Add this at the top of your CSS file, right after :root */
html {
  scroll-behavior: smooth;
}

/* Global Styles */
:root {
  --primary-color: #1a1a1a;
  --accent-color: #8b7355;  /* Warm piano wood color */
  --light-color: #f5f5f5;
  --transition: all 0.3s ease;
}

.App {
  font-family: 'Playfair Display', serif;  /* Elegant serif font */
  color: var(--primary-color);
}

/* Header Styles */
.main-header {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5) 0%,     /* Start dark */
    rgba(0, 0, 0, 0.45) 20%,   /* Slightly lighter */
    rgba(0, 0, 0, 0.35) 40%,   /* Continue fading */
    rgba(0, 0, 0, 0.25) 60%,   /* Even lighter */
    rgba(0, 0, 0, 0.15) 80%,   /* Very light */
    rgba(0, 0, 0, 0) 100%      /* Fully transparent */
  );
  backdrop-filter: blur(.5px);
  -webkit-backdrop-filter: blur(2px);
  padding: 0.7rem 5%;  /* Reduced from 1rem to 0.7rem */
  position: fixed;
  width: 90%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  cursor: default;
}

.main-header.scrolled {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 0%,     /* Start darker */
    rgba(0, 0, 0, 0.65) 20%,   /* Gradual fade */
    rgba(0, 0, 0, 0.55) 40%,   /* Continue fading */
    rgba(0, 0, 0, 0.45) 60%,   /* Still fading */
    rgba(0, 0, 0, 0.35) 80%,   /* Light but still visible */
    rgba(0, 0, 0, 0.25) 100%   /* Lightest but not fully transparent */
  );
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.main-header h1 {
  color: white;
  font-size: 1.8rem;  /* Slightly reduced if needed */
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;  /* Pointer cursor only on the text */
  transition: opacity 0.3s ease;
}

.main-header h1:hover {
  opacity: 0.9;
}

nav ul {
  display: flex;
  gap: 2rem;  /* Reduced from 3rem */
  list-style: none;
  padding: 0;
  margin: 0;
}

nav a {
  color: white;  /* White text */
  text-decoration: none;
  font-size: 0.9rem;  /* Slightly reduced if needed */
  font-weight: 500;   /* Made nav links a bit bolder too */
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: var(--transition);
  padding-bottom: 0.3rem;
  position: relative;
}

/* Updated hover effect for better visibility on dark background */
nav a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;  /* White underline */
  transition: var(--transition);
}

nav a:hover::after {
  width: 100%;
}

/* Hero Section */
.hero-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
              url('../public/hero-piano.jpg') center/cover;
  color: var(--light-color);
  padding: 0 2rem;
}

.hero-section h2 {
  font-family: 'Libre Baskerville', serif;
  font-size: 3.8rem;
  font-weight: 700;
  margin: 0;
  line-height: 1;
  letter-spacing: 0.5px;
}

/* Add specific styling for the second h2 */
.hero-section h2:last-of-type {
  margin-bottom: 1.5rem;
}

.hero-section p {
  font-family: 'Playfair Display', serif;  /* Keep the description in Playfair */
  font-size: 1.5rem;
  max-width: 800px;
}

/* Lessons Section */
.lessons-section {
  padding: 5rem 2rem;
  background-color: var(--light-color);
}

.lesson-types {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 3rem auto;
}

.lesson-card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
}

.lesson-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.lesson-card h3 {
  color: var(--accent-color);
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.lesson-card p {
  color: #666;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-header {
    padding: 0.5rem 1rem;
  }
  
  .main-header h1 .cursive-text {
    font-size: 2.5rem;  /* Can be larger now that we have more space */
  }
  
  .main-header h1 .regular-text {
    font-size: 2.3rem;
  }
  
  /* Hide the navigation on mobile */
  .main-header nav {
    display: none;
  }
  
  nav ul {
    gap: 1rem;  /* Even smaller gap on mobile */
  }
  
  nav a {
    font-size: 0.8rem;
  }
  
  .hero-section h2 {
    font-size: 2.5rem;
  }
  
  .hero-section p {
    font-size: 1.2rem;
  }
}

/* Add these new styles while keeping your existing header styles */
.main-header h1 .cursive-text {
  font-family: "Tangerine", cursive;
  font-size: 3.2rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
}

.main-header h1 .regular-text {
  font-family: 'Tangerine', bold;  /* Keep your existing font */
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
}

/* About Section */
.about-section {
  padding: 6rem 2rem;
  background-color: white;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.about-text h2 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
  margin-bottom: 1.5rem;
}

.credentials {
  list-style: none;
  padding: 0;
  margin-top: 2rem;
}

.credentials li {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.2rem;
  color: var(--accent-color);
  margin-bottom: 0.8rem;
  padding-left: 1.5rem;
  position: relative;
}

.credentials li::before {
  content: '♪';
  position: absolute;
  left: 0;
  color: var(--accent-color);
}

.about-image {
  position: relative;
  width: 80%;  /* Reduced from 90% to 85% */
  margin: 0 auto;  /* Keep it centered */
}

.about-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .about-text h2 {
    font-size: 2.5rem;
  }
  
  .about-image {
    order: -1;  /* Image appears before text on mobile */
  }
}

.pricing-section {
  padding: 3rem 2rem;  /* Reduced from 6rem to 3rem at the top */
  background-color: var(--light-color);
  text-align: center;
}

.pricing-section h2 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.pricing-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.pricing-card {
  background: white;
  padding: 2.5rem 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
}

.pricing-card.featured {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--accent-color);
}

.pricing-card:hover {
  transform: translateY(-5px);
}

.pricing-card.featured:hover {
  transform: translateY(-5px) scale(1.05);
}

.pricing-card h3 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.pricing-card .price {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--accent-color);
  margin-bottom: 2rem;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.pricing-card li {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
  color: #666;
}

.pricing-card li::before {
  content: '♪';
  position: absolute;
  left: 0;
  color: var(--accent-color);
}

.pricing-note {
  margin-top: 3rem;
  font-style: italic;
  color: #666;
}

@media (max-width: 768px) {
  .pricing-cards {
    grid-template-columns: 1fr;
    max-width: 400px;
  }
  
  .pricing-card.featured {
    transform: none;
  }
  
  .pricing-card.featured:hover {
    transform: translateY(-5px);
  }
}

/* Video Link Button */
.video-link {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  background: none;
  border: 2px solid var(--accent-color);
  color: var(--accent-color);
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.8rem 1.5rem;
  margin-top: 2rem;
  transition: all 0.3s ease;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.video-link:hover {
  background-color: var(--accent-color);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(139, 115, 85, 0.2);
}

.video-link:active {
  transform: translateY(0);
}

/* Video Modal */
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 2rem;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  animation: modalFadeIn 0.3s ease;
}

.modal-content {
  background: black;
  padding: 1.5rem;
  border-radius: 15px;
  position: relative;
  width: 100%;
  max-width: 900px;
  aspect-ratio: 16/9;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  transform: scale(0.98);
  animation: modalContentScale 0.3s ease forwards;
}

.modal-content iframe {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.close-button {
  position: absolute;
  top: -3rem;
  right: -3rem;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

@keyframes modalFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes modalContentScale {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-content {
    margin: 0 1rem;
  }
  
  .close-button {
    top: -2.5rem;
    right: 0;
  }
}

.schedule-section {
  padding: 4rem 2rem 2rem 2rem; /* Added more top padding */
  background-color: var(--light-color);
  text-align: center;
}

.schedule-divider {
  width: 100px;
  height: 2px;
  background: var(--accent-color);
  margin: 4rem auto 0 auto; /* Adjusted margin to create space after content */
  position: relative;
}

.schedule-divider::before,
.schedule-divider::after {
  content: "♪";
  position: absolute;
  color: var(--accent-color);
  font-size: 1.2rem;
  top: -0.8rem;
}

.schedule-divider::before {
  left: -20px;
}

.schedule-divider::after {
  right: -20px;
}

.schedule-section h2 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 3rem;
}

.schedule-content {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
  text-align: left;
}

.schedule-info h3 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.schedule-info ol {
  list-style-position: inside;
  padding: 0;
}

.schedule-info li {
  margin-bottom: 1.5rem;
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
}

.schedule-action {
  background: white;
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.schedule-action p {
  font-size: 1.2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.schedule-button {
  display: inline-block;
  padding: 1rem 2.5rem;
  background-color: var(--accent-color);
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.schedule-button:hover {
  background-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .schedule-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .schedule-info {
    order: 2;
  }
  
  .schedule-action {
    order: 1;
    padding: 2rem;
  }
}

.contact-section {
  padding: 8rem 2rem 10rem 2rem; /* Increased top and bottom padding */
  background: linear-gradient(to bottom, var(--light-color), white);
  text-align: center;
}

.contact-content {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-section h2 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.contact-intro {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 4rem;
}

.contact-methods {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  max-width: 900px;
  margin: 0 auto;
}

.contact-method {
  padding: 2rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-method:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.12);
}

.contact-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.contact-method h3 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.contact-method a {
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-method a:hover {
  color: var(--primary-color);
}

.contact-method p {
  color: #666;
}

@media (max-width: 768px) {
  .contact-methods {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .contact-section {
    padding: 4rem 2rem;
  }
}

.pricing-subheader {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.4rem;
  color: var(--accent-color);
  margin: -1rem 0 3rem 0;  /* Negative top margin to bring it closer to the header */
  font-style: italic;
  opacity: 0.9;
}
